<i18n>
  {
    "de": {
      "button_text": "Hier klicken",
      "title": "Default AD Test Login"
    },
    "en": {
      "button_text": "Click here",
      "title": "Default AD test login"
    }
  }
</i18n>

<template>
  <div
    class="py-5"
  >
    <h1>
      {{ $t('title') }}
    </h1>
    <b-btn
      data-cy="defaultAdLoginBtn"
      @click="authorize"
    >
      {{ $t('button_text') }}
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'AuthorizeTestPage',
  methods: {
    authorize() {
      const { authProviderId } = this.$route.params;
      const query = {};
      if (authProviderId === this.$config.providers.azure) {
        query.code = 'admin';
        query.state = 'test';
      }
      this.$router.push({ name: 'login', params: { authProviderId }, query });
    },
  },
};
</script>
